import axios from 'axios'

const API_PREFIX = "estadisticas-reclamos";

const ENDPOINTS = {
    indicesReclamos: params => axios(`${API_PREFIX}/indices-reclamos`, { params }),
    indicesReclamosFechas: params => axios(`${API_PREFIX}/indices-reclamos-fechas`, { params }),
    verMasReclamos: params => axios(`${API_PREFIX}/indices-reclamos-modal`, { params }),
};

export default ENDPOINTS
