<template>
    <section class="px-3 pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 138px)">
        <div class="row mx-0">
            <p class="col-12 px-0 f-18 text-general f-500">
                Índice de reclamos
            </p>
        </div>
        <el-tooltip placement="bottom" effect="light">
            <div slot="content" style="width:200px;">
                <div class="row mx-0 mb-2 text-general2 pb-2 border-bottom f-600 f-14 justify-center">
                    Reclamos
                </div>
                <div v-for="(reclamo, r) in reclamos" :key="r" class="row mx-2 justify-content-center py-2">
                    <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${reclamo.color}`" />
                    <div class="col">
                        {{ reclamo.motivo }}
                    </div>
                    <div class="col-auto">
                        {{ agregarSeparadoresNumero(reclamo.porcentaje_r,1) }}%
                    </div>
                </div>
            </div>
            <div class="d-flex mx-0 br-5" style="height:30px;background-color:#EBEBEB;">
                <template v-if="reclamos.length">
                    <div
                    v-for="(reclamo,r) in reclamos" :key="r"
                    :class="`${reclamos.length == 1 ? 'br-5' : r === 0 ? 'br-l-5' : (r === (reclamos.length - 1) ? 'br-r-5' : '')}`"
                    :style="`width:${porcentajesBordes(reclamo.porcentaje_r)}%;backgroundColor:${reclamo.color}`"
                    />
                </template>
            </div>
        </el-tooltip>
        <div class="row mx-0 py-2">
            <div class="col-auto px-0 text-general f-15">
                Reclamación del {{ porcentajesBordes(porcentajesReclamos.porcentaje) }}% ({{ agregarSeparadoresNumero(porcentajesReclamos.totalReclamos) }})
            </div>
            <div class="col-auto px-0 text-general f-15 ml-auto">
                Análisis sobre {{ agregarSeparadoresNumero(porcentajesReclamos.total) }} pedidos solicitados
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0">
            <p class="col-12 text-general f-18 f-500 px-0">
                Reclamos generados en los últimos 12 meses
            </p>
            <div class="col-12">
                <echart :options="calendario" />
            </div>
        </div>
        <div class="row mx-0 pt-5">
            <div class="col-auto text-general f-500 d-middle">
                Rango de análisis
            </div>
            <div class="col-auto">
                <el-select v-model="rango" placeholder="Seleccionar" size="small">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-0 mx-2" style="width:248px;">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-auto">
                <div class="btn-general px-3 f-14" @click="informe">
                    <!-- <div class="cr-pointer text-white p-1 px-3 f-18 br-12 bg-general"> -->
                    Mostrar informe
                </div>
            </div>
            <cargando v-if="cargandoInformes" mini />
        </div>
        <div class="row mx-0 py-3" />
        <div class="row mx-0">
            <p class="col-12 px-0 f-18 text-general f-500">
                Índice de reclamos según la fecha
            </p>
        </div>
        <el-tooltip placement="bottom" effect="light">
            <div slot="content" style="width:200px;">
                <div class="row mx-0 mb-2 text-general2 pb-2 border-bottom f-600 f-14 justify-center">
                    Reclamos
                </div>
                <div v-for="(reclamo, r) in reclamosFechas" :key="r" class="row mx-2 justify-content-center py-2">
                    <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${reclamo.color}`" />
                    <div class="col">
                        {{ reclamo.motivo }}
                    </div>
                    <div class="col-auto">
                        {{ agregarSeparadoresNumero(reclamo.porcentaje_r,1) }}%
                    </div>
                </div>
            </div>
            <div class="d-flex mx-0 br-5" style="height:30px;background-color:#EBEBEB;">
                <template v-if="reclamosFechas.length">
                    <div
                    v-for="(reclamo,r) in reclamosFechas" :key="r"
                    :class="`${reclamosFechas.length == 1 ? 'br-5' : r === 0 ? 'br-l-5' : (r === (reclamosFechas.length - 1) ? 'br-r-5' : '')}`"
                    :style="`width:${porcentajesBordes(reclamo.porcentaje_r)}%;backgroundColor:${reclamo.color}`"
                    />
                </template>
            </div>
        </el-tooltip>
        <div class="row mx-0 py-2">
            <div class="col-auto px-0 text-general f-15">
                Reclamación del {{ porcentajesBordes(porcentajesReclamosFechas.porcentaje) }}% ({{ agregarSeparadoresNumero(porcentajesReclamosFechas.totalReclamos) }})
            </div>
            <div class="col-auto px-0 text-general f-15 ml-auto">
                Análisis sobre {{ agregarSeparadoresNumero(porcentajesReclamosFechas.total) }} pedidos solicitados
            </div>
        </div>
        <div class="row mx-0 py-5" />
        <div class="row mx-0 align-items-center mb-4">
            <div class="col-auto text-general f-16 f-600">
                Cedis con más reclamos
            </div>
            <div class="col-auto ml-auto">
                <div class="bg-general3 text-white px-3 br-20 py-1 cr-pointer" @click="verMas(1)">
                    Ver más CEDIS
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div v-for="(cedi,c) in cedisReclamos" :key="c" class="mx-1 cr-pointer tres-puntos" style="width:116px;">
                <img :src="cedi.imagen" width="116" height="116" class="obj-cover br-12 border" />
                <p class="text-general text-center tres-puntos f-16 f-600 mt-2">
                    {{ agregarSeparadoresNumero(cedi.cantidad) }}
                </p>
                <p class="text-general text-center my-2 tres-puntos">
                    {{ separadorNumero(cedi.total) }}
                </p>
                <p class="text-center text-general my-2 tres-puntos">
                    <i> {{ cedi.nombre }} </i>
                </p>
            </div>
        </div>
        <div class="row mx-0 py-5" />
        <div class="row mx-0 align-items-center mb-4">
            <div class="col-auto text-general f-16 f-600">
                {{ $config.vendedor }} con más reclamos
            </div>
            <div class="col-auto ml-auto">
                <div class="bg-general3 text-white px-3 br-20 py-1 cr-pointer" @click="verMas(2)">
                    Ver más {{ $config.vendedor }}
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div v-for="(leechero, l) in leecherosReclamos" :key="l" class="mx-1 cr-pointer tres-puntos" style="width:116px;">
                <img :src="leechero.imagen" width="116" height="116" class="obj-cover br-12" />
                <p class="text-general tres-puntos text-center f-16 f-600 mt-2">
                    {{ agregarSeparadoresNumero(leechero.cantidad) }}
                </p>
                <p class="text-general tres-puntos text-center my-2">
                    {{ separadorNumero(leechero.total) }}
                </p>
                <p class="text-center text-general my-2">
                    <i> {{ leechero.nombre }} </i>
                </p>
            </div>
        </div>
        <!-- Partials -->
        <modal-reclamos ref="modalReclamo" :titulo="tituloModal" />
    </section>
</template>

<script>
import Reclamos from '~/services/estadisticas_reclamos'
import {mapGetters} from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
let colorReclamos = [ '#FF9539', '#03D6BC', '#6D3CE1', '#FFDF75', '#284367', '#0892DD', '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88',]

export default {
    components:{
        modalReclamos: () => import('./partials/modalReclamos')
    },
    data(){
        return {
            indice_reclamos: [
                {
                    porcentaje: 50,
                    nombre: 'Otro',
                    color: '#284367'
                },
                {
                    porcentaje: 25,
                    nombre: 'Producto vencido',
                    color: '#FFDF75'
                },
                {
                    porcentaje: 10,
                    nombre: 'Me cobró mas el domicilio',
                    color: '#6D3CE1'
                },
                {
                    porcentaje: 8,
                    nombre: 'Producto más costoso',
                    color: '#03D6BC'
                },
                {
                    porcentaje: 8,
                    nombre: 'Me cobró la bolsa',
                    color: '#FF9539'
                }
            ],
            reclamos: [],
            porcentajesReclamos: {
                total: 0,
                totalReclamos: 0,
                porcentaje: 0,
            },
            reclamosFechas: [],
            porcentajesReclamosFechas: {
                total: 0,
                totalReclamos: 0,
                porcentaje: 0,
            },
            cedisReclamos: [],
            leecherosReclamos: [],
            // reclamos_generados: {
            //     calendar: {
            //         top: 120,
            //         left: 30,
            //         right: 30,
            //         cellSize: ['auto', 13],
            //         range: '2016',
            //         itemStyle: {
            //             borderWidth: 0.5,
            //         },
            //         yearLabel: { show: false },
            //     },
            //     series: {
            //         type: 'heatmap',
            //         coordinateSystem: 'calendar',
            //         data: getVirtulData(2016),
            //     },
            // },
            calendario: {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        color : '#000000',
                        fontWeight: 'bold'
                    }
                },
                tooltip: {
                    position: 'top'
                },
                visualMap: {
                    min: 0,
                    max: 1000,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    top: 'bottom',
                    inRange: {
                        color: ['#FF9D32','#FF2525']
                    },
                },
                calendar: [{
                    range: this.range_fecha(),
                    yearLabel: {
                        show: false
                    },
                    monthLabel: {nameMap: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']},
                    dayLabel: {nameMap: ['D', 'L', 'M', 'M', 'J', 'V', 'S'], margin: 13},
                    cellSize: ['auto', 20]
                }],
                series: {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: [],
                    tooltip: {
                        formatter: param => {
                            const data = param.data
                            return [
                                `<span style="color:${param.color};"></span> ${moment(data[0]).format('DD MMM YYYY')}<br/> `,
                                `<span style="color:${param.color};"></span> ${this.agregarSeparadoresNumero(data[2])} Reclamos <br/> ${this.separadorNumero(data[1])}<br/> `
                            ].join('')
                        }
                    }
                },
            },
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            cargandoIndices: false,
            cargandoInformes: false,
            tituloModal: 1,
            value: '',
            url_image: 'https://directivosygerentes.es/wp-content/uploads/2019/02/big-data-supermercados.jpg'
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(val == null) return
            this.cargando()
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoBarras = true
            }
            this.cargandoInformes = true
        },
        async montar(){
            try {
                if(this.id_moneda == null || this.id_cedis == null ){
                    setTimeout(async() => {
                        await this.indicesReclamos()
                        await this.indicesReclamosFechas()
                    }, 1000);
                }else{
                    await this.indicesReclamos()
                    await this.indicesReclamosFechas()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        range_fecha(){
            var year = moment().endOf('month')
            year = year.format('YYYY-MM-DD')
            var year2 = moment().startOf('month').subtract(11, 'months')
            year2 = year2.format('YYYY-MM-DD')
            const respueta = [year2, year]
            return respueta
        },
        verMas(numTitle){
            this.tituloModal = numTitle;
            this.$refs.modalReclamo.toggle(this.fecha, numTitle);
        },
        async indicesReclamos(){
            try {
                this.cargandoIndices = true
                const params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Reclamos.indicesReclamos(params)
                // this.reclamos = data.reclamos

                let reclamos = data.reclamos

                for (let index = 0; index < reclamos.length; index++){
                    reclamos[index].color = colorReclamos[index]
                }
                this.reclamos = reclamos
                this.porcentajesReclamos = data.porcentajesReclamos
                this.getVirtulData3(data.calendario)

            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargandoIndices = false
            }
        },
        async indicesReclamosFechas(){
            try {
                this.cargandoInformes = true
                const params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Reclamos.indicesReclamosFechas(params)
                // this.reclamos = data.reclamos
                this.reclamosFechas = []

                let reclamosFechas = data.reclamos

                for (let index = 0; index < reclamosFechas.length; index++){
                    reclamosFechas[index].color = colorReclamos[index]
                }
                console.log({reclamosFechas});
                this.reclamosFechas = reclamosFechas
                this.porcentajesReclamosFechas = data.porcentajesReclamos
                this.cedisReclamos = data.cedis
                this.leecherosReclamos = data.leecheros

            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargandoInformes = false
            }
        },
        getVirtulData3(data){

            const anio = data.anio
            const recorrerFechas = data.calendario
            var date = +echarts.number.parseDate(anio[0])
            var end = +echarts.number.parseDate(anio[1])
            var dayTime = 3600 * 24 * 1000
            var array = []
            for (var time = date; time <= end; time += dayTime){
                recorrerFechas.forEach(element => {
                    if (moment(element.fecha).format('DD/MM/YYYY') === echarts.format.formatTime('dd/MM/yyyy', time)){
                        array.push([
                            echarts.format.formatTime('yyyy-MM-dd', time),
                            element.total,
                            element.cantidad,
                        ])
                    }
                })
            }
            this.calendario.series.data = array
            this.calendario.visualMap.max = data.rango
        },
        informe(){
            try {
                this.cargando(true)
                this.indicesReclamosFechas()
            } catch (error){

            }
        }
    }
}
</script>
